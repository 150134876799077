button {
  all: unset;
  cursor: pointer;
  background-color: white;
}

nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 30px;
  padding-right: 40px;
  margin: 40px 0;
  height: 90%;
}

.nav-mobile {
  display: none;
  margin: 10px;
  padding: 5px;
  width: 26px;
  position: fixed;
  top: 0;
  right: 0;
}

h1, h2, h3 {
  background-color: white;
}

h3 {
  text-decoration: underline;
}

a:visited {
  color: black;
}

.app {
  text-align: center;
  font-family: monospace;
  padding: 0 120px;
  height: 100vh;
}
 .show {
   display: auto;
 }

 .hide {
   display: none !important;
 }

.navitem {
  display: block;
  margin: 10px 0;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.nav-active:before {
  content: "> ";
  color: red;
}

.header-name {
  font-size: 50px;
  background-color: white;
}

.content-wrapper {
  position: absolute;
  right: 100px;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  padding: 0 0 0 100px;
  font-size: 16px;
  line-height: 33px;
  max-width: 800px;
}

.image-container {
  max-width: 500px;
  align-self: end;
}

img {
  width: 100%;
}

p {
  background-color: white;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
}


